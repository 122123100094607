import React, { useEffect, useState } from 'react';
import {
  JulianDate,
} from 'cesium';
import {Popover, PopoverTrigger, PopoverContent} from "@nextui-org/react";
import { SimulationType } from 'types';
import { getItemByKey } from 'utilities';

export const AltitudesControl: React.FC<any> = ({viewerRef, data, options, legends, portalContainer, isStaticPanel}) => {
  const [currentAltitude, setCurrentAltitude] = useState<any>({});

  const showAltitudeList = (isTruncate = false) => {
    return legends.map((item: any, index: number) => (
      item.is_selected && (
        <div key={index} className="flex cursor-pointer items-center">
            <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
            <span className='rounded-full w-1 h-1'
              style={{ backgroundColor: item.color }}
            ></span>
            <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
            <span>: {currentAltitude[item.name] || 0}</span>
        </div>
      )
    ))
  }

  useEffect(() => {
    const viewer = viewerRef.current.cesiumElement;
    if (viewer && data.series.length >= 1) {
      const altFieldName = options.simulationType === SimulationType.GNC ? 'pos_1' : 'altitude';
      const updateTime = (clock: any) => {
        const currentTimestamp = Math.floor(JulianDate.toDate(clock.currentTime).getTime()/1000) * 1000;
        const currentSeconds = JulianDate.toDate(clock.currentTime).getSeconds();
        const currentMiliseconds = JulianDate.toDate(clock.currentTime).getMilliseconds().toString().padStart(3, '0');
        const fullSeconds = parseFloat(`${currentSeconds}.${currentMiliseconds}`);

        for (const serie of data.series) {
          const timeData = getItemByKey('name', 'time', serie.fields)?.values || [];
          const altData = getItemByKey('name', altFieldName, serie.fields)?.values || [];
          let currentIndex = timeData.map((date: any)=>new Date(date).getTime()).indexOf(currentTimestamp);
          if (options.simulationType === SimulationType.GNC) {
            let shouldBreak = false;
            timeData.forEach((item: any, index: number) => {
              if (shouldBreak) {
                return;
              }
              if (parseFloat(item) === fullSeconds) {
                shouldBreak = true;
                currentIndex = index;
              }
            })
          }

          if (currentIndex > -1) {
            const height = Number(altData[currentIndex]).toFixed(2) ;
            setCurrentAltitude((oldVal: any) => ({...oldVal, [serie.refId]: height }));
          } 
        };
      }
      viewer.clock.onTick.addEventListener(updateTime);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewerRef.current?.cesiumElement, data, options?.modelAssetId, options?.modelAssetUri]);

  return (
    <div className={`${isStaticPanel ? 'text-sm' : 'text-base min-w-60'} text-white font-sans flex gap-x-2 justify-end items-center `}>
      Altitudes:
      {
        legends.filter((item: any) => item.is_selected)?.length === 1 && (
          <div className="flex flex-col max-h-[21px] overflow-hidden">
            {showAltitudeList(true)}
          </div>
        )
      }
      {
        legends.filter((item: any) => item.is_selected)?.length > 1 && (
          <Popover size="lg" placement="bottom-end" offset={50} showArrow={true} className="before:bg-[#00000050]" portalContainer={portalContainer}>
            <PopoverTrigger>
              <i className="cursor-pointer bi bi-chevron-double-down text-[16px] text-white"></i>
            </PopoverTrigger>
            <PopoverContent className="bg-[#00000050]">
              <div className="px-1 py-2 text-[16px] text-white">
                {showAltitudeList()}
              </div>
            </PopoverContent>
          </Popover>
        )
      }
      
    </div>
  );
}
