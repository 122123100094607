import React, { useEffect, useState, useRef } from 'react';
import { PanelProps, DataHoverEvent, LegacyGraphHoverEvent } from '@grafana/data';
import { AssetMode, SimpleOptions, SimulationType, PanelType } from 'types';
import { toHourString, getColor, generateDescription, getItemByKey, lockCamera, initDate, getTimeRange, isValidCoordinates, movePositionByDistance, sortByNameWithNumber, createDispersionDesc } from 'utilities';

import { Viewer, Clock, Entity, ModelGraphics, PathGraphics } from 'resium';
import {
  Ion,
  JulianDate,
  TimeInterval,
  TimeIntervalCollection,
  Cartesian3,
  Quaternion,
  Transforms,
  SampledProperty,
  SampledPositionProperty,
  Color,
  PolylineDashMaterialProperty,
  IonResource,
  Cartesian2,
  ClockRange,
  VelocityOrientationProperty,
  Math as MathCesium,
  HeadingPitchRoll,
  Ellipsoid,
  Matrix4,
  DebugModelMatrixPrimitive,
  LabelStyle,
  VerticalOrigin,
} from 'cesium';

import 'cesium/Build/Cesium/Widgets/widgets.css';
import '../css/output.css';
import { TopControl } from './TopControl';
import { Legend, SeriesLegend } from './SeriesLegend';
import 'bootstrap-icons/font/bootstrap-icons.css';

interface Props extends PanelProps<SimpleOptions> { }

const DEFAULT_LAT = 28.60838888888887;
const DEFAULT_LONG = -80.60433333333319;
const DEFAULT_HEIGHT = 10000;
const DEFAULT_PITCH = -1.5708;

const setCameraFly = async (viewer: any, lat: number, long: number, options: any, finishCallback: any = () => { }, renderAxis: any) => {

  const pitch = MathCesium.toRadians(-45.0);
  const mainPanelHeight = options?.panelType === PanelType.dispersion ? 1000000 : 20000;
  const staticPanelHeight = 150;
  const isStaticPanel = options?.panelType === PanelType.static;
  const cameraHeight = isStaticPanel ? staticPanelHeight : mainPanelHeight;
  const displacementMeters = isStaticPanel ? 100 : 120;
  const statisLatChange = (displacementMeters / 111000);
  const mainLatChange = statisLatChange * (mainPanelHeight / staticPanelHeight);
  const newLat = isStaticPanel ? (lat - statisLatChange) : (lat - mainLatChange);
  let position = Cartesian3.fromDegrees(long, newLat, cameraHeight);

  lockCamera(viewer, true);
  await new Promise(resolve => setTimeout(resolve, 1000));
  renderAxis();
  if (isStaticPanel) {
    viewer.trackedEntity = viewer.entities.values[0];
  }
  await new Promise(resolve => setTimeout(resolve, 1000));
  viewer.camera.setView({
    destination: position,
    orientation: {
      heading: 0,
      pitch: pitch,
      roll: 0,
    },
  });
  if (isStaticPanel) {
    viewer.camera.rotateLeft(MathCesium.toRadians(135.0));
  }
  await new Promise(resolve => setTimeout(resolve, 1000));
  finishCallback();
};

const setTimelineViewer = (
  viewer: any,
  data: any,
  setSatelliteAvailability: any,
  setTimestamp: any,
  setShouldAnimate: any
) => {
  let allTimes = getTimeRange(data.series);
  if (!viewer) {
    return;
  }

  const { timeline, clock } = viewer;
  const startTimestamp: Date | null = allTimes[0] ?? null;
  const endTimestamp: Date | null = allTimes.at(-1) ?? null;

  if (timeline) {
    timeline.makeLabel = function (date: any) {
      const timeBySecond = JulianDate.secondsDifference(date, JulianDate.fromDate(startTimestamp));
      return toHourString(timeBySecond);
    };
  }

  if (startTimestamp !== null) {
    setTimestamp(JulianDate.fromDate(startTimestamp));
  } else {
    setTimestamp(null);
  }

  if (startTimestamp && endTimestamp) {
    setSatelliteAvailability(
      new TimeIntervalCollection([
        new TimeInterval({
          start: JulianDate.fromDate(startTimestamp),
          stop: JulianDate.fromDate(endTimestamp),
        }),
      ])
    );
  } else {
    setSatelliteAvailability(null);
  }

  if (viewer && clock && timeline && startTimestamp && endTimestamp) {
    const start = JulianDate.fromDate(startTimestamp);
    const stop = JulianDate.fromDate(endTimestamp);
    clock.startTime = start.clone();
    clock.stopTime = stop.clone();
    clock.currentTime = start.clone();
    clock.clockRange = ClockRange.LOOP_STOP;
    timeline.zoomTo(start, stop);
    setShouldAnimate(false);
  }
};

export const SatelliteVisualizer: React.FC<Props> = ({ options, data, timeRange, width, height, eventBus }) => {
  Ion.defaultAccessToken = options.accessToken;
  const viewerRef = useRef<any>(null);
  const modelRef = useRef<any>(null);
  const wrapRef = useRef<any>(null);

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isFinishLoad, setIsFinishLoad] = useState<boolean>(false);
  const [isBack, setIsBack] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState<JulianDate | null>(null);
  const [satelliteAvailability, setSatelliteAvailability] = useState<TimeIntervalCollection | null>(null);
  const [satellitePositions, setSatellitePositions] = useState<SampledPositionProperty[] | null>(null);
  const [satelliteOrientations, setSatelliteOrientations] = useState<SampledProperty[] | null>(null);
  const [satelliteResources, setSatelliteResources] = useState<IonResource[] | string[] | any[]>([]);
  const [clockMultiplier, setClockMultiplier] = useState<number>(1);
  const [hoveredIndex, setHoveredIndex] = useState<{ serie: number; position: number } | null>(null);
  const [legends, setLegends] = useState<Legend[]>([]);
  const [series, setSeries] = useState<any[]>([]);

  const isStaticPanel = () => options?.panelType === PanelType.static;

  const addCircleToViewer = (id: string, viewer: any, position: any, radius: any, description: any, color: any = Color.RED.withAlpha(0.3)) => {
    if (options.panelType === PanelType.dispersion && radius > 0) {
      const finalRadius = options?.dispersionDistanceUnit === 'm' ? parseFloat(radius) : (radius * 1000);
      viewer.entities.removeById(id);
      viewer.entities.add({
        position: position,
        id: id,
        ellipse: {
          semiMinorAxis: finalRadius,
          semiMajorAxis: finalRadius,
          material: color,
          height: 100,
        },
        zIndex: radius,
        description
      });
    }
  }

  const initPitch = (inputPitch: any) => {
    if (options?.simulationType === SimulationType.GNC) {
      return (DEFAULT_PITCH + inputPitch);
    }
    return inputPitch;
  }

  const handleMouseMove = (serie: number, position: number) => {
    setHoveredIndex({ serie, position });
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleSpeedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseFloat(event.target.value);
    dispatchAction('speedChange', { speed: value });
  };

  const playReverse = () => {
    const currentSpeed = clockMultiplier === 0 ? -1 : clockMultiplier;
    const speed = currentSpeed > 0 ? currentSpeed * -1 : currentSpeed;
    dispatchAction('playReverse', { speed, back: true, play: true });
  };

  const playForward = () => {
    const currentSpeed = clockMultiplier === 0 ? 1 : clockMultiplier;
    const speed = currentSpeed < 0 ? currentSpeed * -1 : currentSpeed;
    dispatchAction('playForward', { speed, back: false, play: true });
  };

  const pause = () => {
    dispatchAction('pause', {});
  };

  const initLegends = (series: any) => {
    let newLegends: any = [];
    for (const [index, serie] of series.entries()) {
      newLegends = [...newLegends, { id: index, name: serie.refId || '', color: getColor(index), is_selected: true }];
    }
    newLegends = sortByNameWithNumber(newLegends);
    setLegends(newLegends);
  };
  const getFieldData = (serieData: any) => {
    let longFieldName = 'longitude';
    let latFieldName = 'latitude';
    let pitchFieldName = 'pitch';
    let heightFieldName = 'altitude';
    if (options.panelType === PanelType.dispersion) {
      longFieldName = 'Longitude_IIP';
      latFieldName = 'Latitude_IIP';
    }
    if (options.simulationType === SimulationType.GNC) {
      pitchFieldName = 'theta';
      heightFieldName = 'pos_1';
    }
    const fields = [
      { name: 'time', key: 'timeData' },
      { name: longFieldName, key: 'longData' },
      { name: latFieldName, key: 'latData' },
      { name: heightFieldName, key: 'altData' },
      { name: 'RSS_plus_dis_IIP', key: 'rssData' },
      { name: 'q_B_ECI_x', key: 'q_B_ECI_xData' },
      { name: 'q_B_ECI_y', key: 'q_B_ECI_yData' },
      { name: 'q_B_ECI_z', key: 'q_B_ECI_zData' },
      { name: 'q_B_ECI_s', key: 'q_B_ECI_sData' },
      { name: 'yaw', key: 'headingData' },
      { name: pitchFieldName, key: 'pitchData' },
      { name: 'roll', key: 'rollData' },
      { name: 'event', key: 'eventData' },
      { name: 'pos_2', key: 'distanceData' },
    ];

    const result: any = {};

    fields.forEach(({ name, key }) => {
      result[key] = getItemByKey('name', name, serieData.fields)?.values || [];
    });
    if (options.simulationType === SimulationType.GNC) {
      result.longData = Array(result.timeData.length).fill(DEFAULT_LONG); 
      result.latData = Array(result.timeData.length).fill(DEFAULT_LAT); 
      result.headingData = Array(result.timeData.length).fill(0); 
      result.rollData = Array(result.timeData.length).fill(0); 
    }

    return result;
  };

  const setResources = (dataFrame: any, index: number) => {
    const modelAssetId = dataFrame.fields[12]?.values[0];
    if (modelAssetId) {
      IonResource.fromAssetId(modelAssetId, { accessToken: options.accessToken })
        .then((resource) => {
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: resource,
          }));
        })
        .catch((error) => {
          console.error('Error loading Ion Resource of Model:', error);
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: null,
          }));
        });
      return;
    }

    if (options.modelAssetId) {
      IonResource.fromAssetId(options.modelAssetId, { accessToken: options.accessToken })
        .then((resource) => {
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: resource,
          }));
        })
        .catch((error) => {
          console.error('Error loading Ion Resource of Model:', error);
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: null,
          }));
        });
      return;
    }
    if (options.modelAssetUri) {
      setSatelliteResources(oldVal => ({
        ...oldVal,
        [index]: options.modelAssetUri,
      }));
      return;
    }

    setSatelliteResources(oldVal => ({
      ...oldVal,
      [index]: null,
    }));
  };

  useEffect(() => {
    const currentDate = new Date();
    const timeInterval = new TimeInterval({
      start: JulianDate.addDays(JulianDate.fromDate(currentDate), -7, new JulianDate()),
      stop: JulianDate.fromDate(currentDate),
    });
    // https://community.cesium.com/t/correct-way-to-wait-for-transform-to-be-ready/24800
    Transforms.preloadIcrfFixed(timeInterval).then(() => setLoaded(true));
  }, [timeRange]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (data.series.length) {
      const positionProperties = [];
      const orientationProperties = [];
      const initSeries = [];
      const viewer = viewerRef?.current?.cesiumElement;
      setTimelineViewer(viewer, data, setSatelliteAvailability, setTimestamp, setShouldAnimate);
      initLegends(data.series);
      for (const [serieIndex, serie] of data.series.entries()) {
        const positionProperty = new SampledPositionProperty();
        const orientationProperty = new SampledProperty(Quaternion);
        const { timeData, longData, latData, altData, q_B_ECI_xData, q_B_ECI_yData, q_B_ECI_zData, q_B_ECI_sData, headingData, pitchData, rollData, eventData, distanceData } = getFieldData(serie);

        const serieData = [];
        const serieColor = getColor(serieIndex);

        for (const [index, value] of timeData.entries()) {
          if (!isValidCoordinates(latData[index], longData[index])) {
            continue;
          }
          const time = JulianDate.fromDate(initDate(value));
          const dataIndex = (options.panelType === PanelType.static) ? 0 : index;
          let description: any;
          let pixelSize = options.trajectoryPositionSize;
          let latVal = latData[dataIndex];
          let longVal = longData[dataIndex];

          if (options?.simulationType === SimulationType.GNC) {
            const finalCoordinate = movePositionByDistance(latVal, longVal, distanceData[index]);
            latVal = finalCoordinate.latitude;
            longVal = finalCoordinate.longitude;
          }

          const x_ECEF = Cartesian3.fromDegrees(
            Number(longVal),
            Number(latVal),
            Number(altData?.[dataIndex] || 0)
          );
          let q_B_ECI = new Quaternion();
          if (headingData?.length && pitchData?.length && rollData?.length) {
            const heading = Number(headingData?.[index]);
            const pitch = Number(pitchData?.[index]);
            const roll = Number(rollData?.[index]);
            const fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator('north', 'west');
            const ellipsoid = Ellipsoid.WGS84;
            q_B_ECI = Transforms.headingPitchRollQuaternion(x_ECEF, new HeadingPitchRoll(heading, initPitch(pitch), roll), ellipsoid, fixedFrameTransform);
          }
          if (options.simulationType?.toLowerCase() === SimulationType.SixDoF?.toLowerCase()) {
            q_B_ECI = new Quaternion(
              Number(q_B_ECI_xData[index]),
              Number(q_B_ECI_yData[index]),
              Number(q_B_ECI_zData[index]),
              Number(q_B_ECI_sData[index])
            );
          }
          positionProperty.addSample(time, x_ECEF);
          orientationProperty.addSample(time, q_B_ECI);

          if (options?.simulationType?.toLowerCase() === SimulationType.ThreeDoF.toLowerCase() && options?.panelType !== PanelType.dispersion) {
            if (eventData[index]) {
              pixelSize = options.trajectoryPositionSize * 2;
            }
            description = generateDescription(
              toHourString(
                JulianDate.secondsDifference(
                  time,
                  JulianDate.fromDate(new Date(timeData[0]))
                )
              ),
              longData[index],
              latData[index],
              altData[index],
              eventData[index]
            );
          }
          serieData.push({ time: timeData[index], position: x_ECEF, color: serieColor, pixelSize, event: eventData[index], description });
        }

        positionProperties.push(positionProperty);
        orientationProperties.push(orientationProperty);
        initSeries.push(serieData);
      }
      setSeries(initSeries);
      setSatellitePositions(positionProperties);
      setSatelliteOrientations(orientationProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoaded, options]);

  const checkViewerAvailable: any = async () => {
    const viewer = viewerRef?.current?.cesiumElement;
    if (viewer && data?.series?.length) {
      if (isStaticPanel()) {
        viewer.scene.globe.show = false;
        viewer.scene.skyBox.show = false;
        viewer.scene.skyAtmosphere.show = false;
        viewer.scene.sun.show = false;
      }
      const { timeData, longData, latData, altData, rssData } = getFieldData(data.series[0]);
      const firstLat = Number(latData[0]) || DEFAULT_LAT;
      const firstLong = Number(longData[0]) || DEFAULT_LONG;
      const firstHeight = Number(altData[0]) || DEFAULT_HEIGHT;

      const renderAxis = () => drawAxis(firstLong, firstLat, firstHeight);

      for (const [index, value] of timeData.entries()) {
        if (!isValidCoordinates(latData[index], longData[index])) {
          continue;
        }
        const position = Cartesian3.fromDegrees(
          Number(longData[index]),
          Number(latData[index]),
          0
        );
        const description = createDispersionDesc(
          value,
          longData[index],
          latData[index],
          rssData[index]
        );
        addCircleToViewer(`circle_${index}`, viewer, position, rssData[index], description);
      }

      setCameraFly(viewer, firstLat, firstLong, options, () => {
        setIsFinishLoad(true);
        lockCamera(viewer, false);
      }, renderAxis);
    } else {
      await new Promise(resolve => setTimeout(resolve, 500));
      await checkViewerAvailable();
    }
  };

  const drawAxis = (long: any, lat: any, height: any) => {
    if (!isStaticPanel()) {
      return;
    }
    const viewer = viewerRef?.current?.cesiumElement;
    const fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator('north', 'west');
    const length = 50;
    const position = Cartesian3.fromDegrees(long, lat, height);
    const modelMatrix = Transforms.headingPitchRollToFixedFrame(
      position,
      new HeadingPitchRoll(),
      Ellipsoid.WGS84,
      fixedFrameTransform,
    );
    viewer.scene.primitives.add(
      new DebugModelMatrixPrimitive({
        modelMatrix: modelMatrix,
        length: length,
        width: 1.0,
      }),
    );
    const xEnd = new Cartesian3(length, 0.0, 0.0);
    const yEnd = new Cartesian3(0.0, length, 0.0);
    const zEnd = new Cartesian3(0.0, 0.0, length);

    const xEndWorld = Matrix4.multiplyByPoint(modelMatrix, xEnd, new Cartesian3());
    const yEndWorld = Matrix4.multiplyByPoint(modelMatrix, yEnd, new Cartesian3());
    const zEndWorld = Matrix4.multiplyByPoint(modelMatrix, zEnd, new Cartesian3());
    addAxisToViewer(viewer, xEndWorld, 'X', Color.RED);
    addAxisToViewer(viewer, yEndWorld, 'Y', Color.GREEN);
    addAxisToViewer(viewer, zEndWorld, 'Z', Color.BLUE);
  }

  const addAxisToViewer = (viewer: any, position: any, text: string, color: any) => {
    viewer.entities.add({
      position: position,
      label: {
        text: text,
        font: '12pt monospace',
        style: LabelStyle.FILL,
        fillColor: color,
        outlineWidth: 10,
        verticalOrigin: VerticalOrigin.BOTTOM,
        pixelOffset: new Cartesian2(0, -9)
      }
    });
  }

  useEffect(() => {
    checkViewerAvailable();

    const handleListenerAction = () => {
      let currentAction = JSON.parse(localStorage.getItem('currentAction') || '{}');
      handleSyncAction(currentAction);
    };

    const params = new URLSearchParams(window.location.search);
    const editPanel = params.get('editPanel');
    if (editPanel === '1') {
      setIsEditMode(true);
    }

    document.addEventListener('dispatchAction', handleListenerAction);
    return () => {
      document.removeEventListener('dispatchAction', handleListenerAction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Ion.defaultAccessToken = options.accessToken;
  }, [options.accessToken]);

  useEffect(() => {
    for (const [index, value] of data.series.entries()) {
      setResources(value, index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options.accessToken, options.modelAssetId, options.modelAssetUri]);

  useEffect(() => {
    if (!options.subscribeToDataHoverEvent) {
      return;
    }

    const dataHoverSubscriber = eventBus.getStream(DataHoverEvent).subscribe((event) => {
      if (event?.payload?.point?.time) {
        setTimestamp(JulianDate.fromDate(new Date(event.payload.point.time)));
      }
    });

    const graphHoverSubscriber = eventBus.getStream(LegacyGraphHoverEvent).subscribe((event) => {
      if (event?.payload?.point?.time) {
        setTimestamp(JulianDate.fromDate(new Date(event.payload.point.time)));
      }
    });

    return () => {
      dataHoverSubscriber.unsubscribe();
      graphHoverSubscriber.unsubscribe();
    };
  }, [eventBus, options.subscribeToDataHoverEvent]);

  useEffect(() => {
    if (viewerRef?.current?.cesiumElement) {
      const viewer = viewerRef.current.cesiumElement;
      viewer.clock.multiplier = isBack ? clockMultiplier * -1 : clockMultiplier;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockMultiplier]);

  const handleSyncAction = (currentAction: any) => {
    const viewer = viewerRef.current?.cesiumElement;
    if (currentAction.playForward) {
      const { speed, back, play } = currentAction.playForward;
      viewer.clockViewModel.multiplier = speed;
      setClockMultiplier(speed);
      setIsBack(back);
      viewer.clockViewModel.shouldAnimate = play;
      setShouldAnimate(play);
    }
    if (currentAction.pause) {
      viewer.clockViewModel.shouldAnimate = !viewer.clockViewModel.shouldAnimate;
      setShouldAnimate(viewer.clockViewModel.shouldAnimate);
    }
    if (currentAction.playReverse) {
      const { speed, back, play } = currentAction.playReverse;
      viewer.clockViewModel.multiplier = speed;
      setIsBack(back);
      viewer.clockViewModel.shouldAnimate = play;
      setShouldAnimate(play);
    }
    if (currentAction.speedChange) {
      const { speed } = currentAction.speedChange
      setClockMultiplier(speed);
    }
    if (currentAction.timelineChange) {
      const { currentTime } = currentAction.timelineChange;
      setTimestamp(currentTime);
    }
    if (currentAction.legendChange) {
      const { legends } = currentAction.legendChange;
      setLegends(legends);
    }
  }

  const dispatchAction = (name: string, value: any) => {
    const currentAction = { [name]: value };
    if (isEditMode) {
      handleSyncAction(currentAction);
      return;
    }
    localStorage.setItem('currentAction', JSON.stringify(currentAction));
    const myEvent = new Event('dispatchAction');
    document.dispatchEvent(myEvent);
  };

  return (
    <div className="relative">
      <div
        className="relative font-sans"
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
        ref={wrapRef}
      >
        <Viewer
          full
          animation={false}
          timeline={true}
          infoBox={options.showInfoBox}
          baseLayerPicker={options.showBaseLayerPicker}
          sceneModePicker={options.showSceneModePicker}
          projectionPicker={options.showProjectionPicker}
          navigationHelpButton={false}
          fullscreenButton={false}
          geocoder={false}
          homeButton={false}
          creditContainer="cesium-credits"
          ref={viewerRef}
          className={isStaticPanel() ? 'panel-sm' : ''}
        >
          {timestamp && <Clock currentTime={timestamp} />}
          {satelliteAvailability &&
            satellitePositions &&
            satelliteOrientations &&
            satellitePositions.map(
              (satellitePosition, index) =>
                legends.length > 0 &&
                legends[index]?.is_selected && (
                  <Entity
                    availability={satelliteAvailability}
                    position={satellitePosition}
                    orientation={
                      options.autoComputeOrientation
                        ? new VelocityOrientationProperty(satellitePosition)
                        : satelliteOrientations[index]
                    }
                    tracked={false}
                    ref={modelRef}
                    key={index}
                    name={data.series[index]?.refId}
                  >
                    {options.assetMode === AssetMode.model && satelliteResources[index] && (
                      <ModelGraphics
                        uri={satelliteResources[index]}
                        scale={options.modelScale}
                        minimumPixelSize={options.modelMinimumPixelSize}
                        maximumScale={options.modelMaximumScale}
                        key={index}
                      />
                    )}

                    {options.trajectoryShow && (
                      <PathGraphics
                        width={options.trajectoryWidth}
                        material={
                          new PolylineDashMaterialProperty({
                            color: Color.fromCssColorString(options.trajectoryColor),
                            dashLength: options.trajectoryDashLength,
                          })
                        }
                      />
                    )}
                  </Entity>
                )
            )}

          {(options.trajectoryPositionShow && !isStaticPanel()) &&
            series.map(
              (serie_item, index) =>
                legends.length > 0 &&
                legends[index]?.is_selected &&
                serie_item.map((item: any, itemIndex: number) => (
                  <Entity
                    position={item.position}
                    point={{
                      pixelSize: item.pixelSize || options.trajectoryPositionSize,
                      color: Color.fromCssColorString(item.color),
                    }}
                    key={index}
                    label={
                      item.event && hoveredIndex?.serie === index && hoveredIndex?.position === itemIndex
                        ? {
                          text: item.event,
                          font: '12px sans-serif',
                          fillColor: Color.WHITE,
                          outlineWidth: 1,
                          outlineColor: Color.BLACK,
                          pixelOffset: new Cartesian2(0, -20),
                        }
                        : undefined
                    }
                    name={data.series[index]?.refId}
                    description={item.description}
                    onMouseMove={() => handleMouseMove(index, itemIndex)}
                    onMouseLeave={handleMouseLeave}
                  ></Entity>
                ))
            )}
        </Viewer>
        <div id="cesium-credits" className={options.showCredits ? 'block' : 'hidden'}></div>
      </div>
      {
        !isStaticPanel() && (
          <>
            <SeriesLegend
              legends={legends}
              onLegendsChange={(newLegends: Legend[]) => dispatchAction('legendChange', { legends: newLegends })}
              portalContainer={wrapRef.current}
            />
            {
              options.panelType !== PanelType.dispersion ? (
                <TopControl
                  viewerRef={viewerRef}
                  data={data}
                  options={options}
                  isPlaying={shouldAnimate}
                  isBack={isBack}
                  setTimestamp={(currentTime: JulianDate) => setTimestamp(currentTime)}
                  playReverse={playReverse}
                  pause={pause}
                  playForward={playForward}
                  handleSpeedChange={handleSpeedChange}
                  currentSpeed={clockMultiplier}
                  legends={legends}
                  isStaticPanel={isStaticPanel()}
                  dispatchAction={dispatchAction}
                  isFinishLoad={isFinishLoad}
                />
              ) : null
            }
          </>
        )
      }
    </div>
  );
};
