import {
  JulianDate,
  Math as MathCesium,
} from 'cesium';



export function formatDateTime(date: any): string {
  if (!date) {
    return '';
  }
  const utcDate = JulianDate.toDate(date);
  const hour = utcDate.getHours().toString().padStart(2, '0');
  const minute = utcDate.getMinutes().toString().padStart(2, '0');
  const second = utcDate.getSeconds().toString().padStart(2, '0');
  return `${hour}:${minute}:${second}`;
}

export function toHourString(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const hoursPad = String(hours).padStart(2, '0');
  const minutesPad = String(minutes).padStart(2, '0');
  const secondsPad = String(remainingSeconds).padStart(2, '0');

  return `${hoursPad}:${minutesPad}:${secondsPad}`;
}

export const degreesToRadians = (degrees: any) => {
  return degrees * (Math.PI / 180);
}

export const getItemByKey = (key: string, compareKey: string, list: any[]) => {
  if (!list?.length) {
    return {};
  }
  return list.find(item => item?.[key]?.toLowerCase() === compareKey.toLowerCase());
}

export function getColor(index: number) {
  return [
    'Red',          // #FF0000
    'Blue',         // #0000FF
    'Purple',       // #800080
    'Green',        // #008000
    'Orange',       // #FFA500
    'Brown',        // #A52A2A
    'Black',        // #000000
    'Teal',         // #008080
    'Maroon',       // #800000
    'Indigo',       // #4B0082
    'Olive',        // #808000
    'Turquoise',    // #40E0D0
    'Silver',       // #C0C0C0
    'Jade',         // #00A86B
    'Crimson',      // #DC143C
    'Azure',        // #007FFF
    'Burgundy',     // #800020
    'Amber',        // #FFBF00
    'Gold',         // #FFD700
    'Sapphire',     // #0F52BA
    'Scarlet',      // #FF2400
    'Cerise',       // #DE3163
    'Rust',         // #B7410E
    'Sangria',      // #92000A
    'SteelBlue',    // #4682B4
    'Raspberry',    // #E30B5D
    'Emerald',      // #50C878
    'Copper',       // #B87333
    'Chocolate',    // #D2691E
    'ConcreteGrey', // #787B73
    'Asparagus',    // #87A96B
    'AshGrey',      // #B2BEB5
    'Ultramarine',  // #153F78
    'Taupe',        // #57483C
    'Firebrick',    // #B22222
    'Harlequin',    // #3FFF00
    'Iris',         // #5A4FCF
    'Thistle',      // #D8BFD8
    'Sienna',       // #A0522D
    'Ecru',         // #C2B280
    'RoyalBlue',    // #4169E1
    'Ruby',         // #E0115F
    'DarkPurple',   // #51434F
    'Bronze',       // #CC7732
    'MossGreen',    // #ABA956
    'Goldenrod',    // #996515
    'Heliotrope',   // #DF73FF
    'DarkOliveGreen', // #556B2F
    'IndianRed',    // #CD5C5C
    'CarrotOrange', // #ED9121
    'Cardinal',     // #C41E3A
    'BrickRed',     // #CB4154
    'Gunmetal',     // #848482
    'AntiqueBrass', // #CD9575
  ][index];
}

export function generateDescription(timeVals: any, longitudeVals: any, latitudeVals: any, altitudeVals: any, event: any) {
  return `
  <table class="table-auto">
    <tr>
     <td>Time</td>
     <td>${timeVals}</td>
    </tr>
    <tr>
      <td>Longitude</td>
      <td>${longitudeVals}</td>
    </tr>
    <tr>
      <td>Latitude</td>
      <td>${latitudeVals}</td>
    </tr>
    <tr>
      <td>Altitude</td>
      <td>${Number(altitudeVals).toFixed(2)}</td>
    </tr>
    ${event ? `<tr>
      <td>Event</td>
      <td>${event}</td>
    </tr>` : ''
    }
  <tr>
  </table>
  `
}

export function createDispersionDesc(timeVals: any, longitudeVals: any, latitudeVals: any, distance: any) {
  return `
  <table class="table-auto" style="font-size: 14px">
    <tr>
     <td style="width: 50%; color: gray">Time</td>
     <td>${timeVals}</td>
    </tr>
    <tr>
      <td style="color: gray">Longitude_IIP</td>
      <td>${longitudeVals}</td>
    </tr>
    <tr>
      <td style="color: gray">Latitude_IIP</td>
      <td>${latitudeVals}</td>
    </tr>
    <tr>
      <td style="color: gray">RSS_plus_dis_IIP</td>
      <td>${distance}</td>
    </tr>
  <tr>
  </table>
  `
}

export function lockCamera(viewer: any, isLock: boolean) {
  viewer.scene.screenSpaceCameraController.enableRotate = !isLock;
  viewer.scene.screenSpaceCameraController.enableTranslate = !isLock;
  viewer.scene.screenSpaceCameraController.enableZoom = !isLock;
  viewer.scene.screenSpaceCameraController.enableTilt = !isLock;
  viewer.scene.screenSpaceCameraController.enableLook = !isLock;
}

function extractTimeComponents(timeString: any) {
  let time = parseFloat(timeString);
  let seconds = Math.floor(time);
  let milliseconds = Math.round((time - seconds) * 1000);
  return {
    seconds: seconds,
    milliseconds: milliseconds
  };
}

export function initDate(time: string) {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  if (!time) {
    return currentDate;
  }
  if (parseInt(time, 10) < 9999) {
    const { seconds, milliseconds } = extractTimeComponents(time);
    currentDate.setSeconds(seconds, milliseconds);
    return currentDate;
  }
  return new Date(time);
}

export function getTimeRange(data: Object[]) {
  const dateRange = data
    .flatMap((item: any) => {
      const timeData = getItemByKey('name', 'time', item.fields)?.values || [];
      return [timeData[0], timeData.at(-1)]
    }).map((time: string) => initDate(time))
    .sort((a: any, b: any) => a - b);
  return dateRange;
}

export function isValidLatitude(latitude: any) {
  return latitude >= -90 && latitude <= 90;
}

export function isValidLongitude(longitude: any) {
  return longitude >= -180 && longitude <= 180;
}

export function isValidCoordinates(latitude: any, longitude: any) {
  return isValidLatitude(latitude) && isValidLongitude(longitude);
}

export function movePositionByDistance(latitude: any, longitude: any, distanceMeters: any) {
  const latitudeRadians = MathCesium.toRadians(latitude);
  const longitudeRadians = MathCesium.toRadians(longitude);
  const earthRadius = 6378137.0;

  const angleDegrees = distanceMeters >= 0 ? -90 : 90;
  const angleRadians = MathCesium.toRadians(angleDegrees);
  const absDistanceMeters = Math.abs(distanceMeters);

  const eastMeters = absDistanceMeters * Math.cos(angleRadians);
  const northMeters = absDistanceMeters * Math.sin(angleRadians);

  const deltaLatitude = northMeters / earthRadius;
  const newLatitude = latitudeRadians + deltaLatitude;

  const radiusAtLatitude = earthRadius * Math.cos(latitudeRadians);
  const deltaLongitude = eastMeters / radiusAtLatitude;
  const newLongitude = longitudeRadians + deltaLongitude;

  const newLatitudeDegrees = MathCesium.toDegrees(newLatitude);
  const newLongitudeDegrees = MathCesium.toDegrees(newLongitude);

  return {
    latitude: newLatitudeDegrees,
    longitude: newLongitudeDegrees
  };
}


export function sortByNameWithNumber(array: any[]) {
  return array.sort((a, b) => {
    const numA = parseInt(a.name.match(/\d+$/)[0], 10);
    const numB = parseInt(b.name.match(/\d+$/)[0], 10);
    if (a.name.replace(/\d+$/, '') === b.name.replace(/\d+$/, '')) {
      return numA - numB;
    }
    return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
  });
}
